import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import ModeSwitcher from './ModeSwitcher'
import VanillaTilt from 'vanilla-tilt';
import Particles from 'react-particles-js';
import { customParticlesOptions } from '../main';
import ContactUsPg from './sections/ContactUsPg';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye, faCube, faDiceD20, faGear, faInfinity, faListUl, faLocationDot, faScaleBalanced, faSpinner, faSquarePollVertical } from '@fortawesome/free-solid-svg-icons';
import Header from "./Header ";


const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

const DigitalMarketingService = () => {
    const [showBackground, setShowBackground] = useState(false);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    const tiltRefs = useRef([]);
    useLayoutEffect(() => {
      tiltRefs.current.forEach((ref) => {
        if (ref) {
          VanillaTilt.init(ref, {
            max: 10, // Maximum tilt rotation (degrees)
            speed: 400, // Speed of the tilt effect
            scale: 1, // Scale factor on tilt
            glare: false, // Enable glare effect
            "max-glare": 0.5, // Maximum glare opacity
          });
        }
      });
    }, [tiltRefs]);
  
    useEffect(() => {
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        if (scrollPosition > 100) {
          setShowBackground(true);
        } else {
          setShowBackground(false);
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

  return (

    <div>
      <Header/>
               {/* ----------------------particles----------------------- */}
      <div>
        <section
          className="page-hero services service-content  d-flex align-items-center reveal-start"
          id="page-hero"
        >
          <div className="particles-js dots" id="particles-js">
            <Particles
              params={{
                ...customParticlesOptions,
              }}
            />
          </div>
          {/* -------------------------home pg */}
          <div className="container">
            <div className="row align-items-center justify-content-center text-center ">
              <div className="col-12 col-lg-9">
                <div className="hero-text-area   ">
                  <div className="content-area ">
                    <h1
                      className="title    wow fadeInUp"
                      data-wow-delay=".4s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.4s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <span className="hollow-text ">Digital Marketing  </span>{" "}
                      <span className="featured-text">
                        {" "}
                        Company
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 500 150"
                          preserveAspectRatio="none"
                        >
                          <path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path>
                        </svg>
                      </span>{" "}
                    </h1>
                  </div>
                  <div className="col-11 ">
                    <p className="hero-subtitle text-center ">
                    Xerotag helps you achieve digital success through cost-effective solutions, delivering brand benefits such as flexibility, efficiency, access to the latest technologies and measurable results for your internet has increased significantly for
                    </p>
                  </div>
                </div>
                <div
                  className=" btn-solid text-center "
                  onClick={() => scrollToSection("contact-us")}
                >
                  get in touch
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>

        <div>
          <section
            className="services services-boxed mega-section"
            id="services"
          >
            <div className="container">
              <div className="sec-heading  ">
                <div className="content-area">
                  <div>
                    <div className="content-area">
                      <h2
                        className="title    wow fadeInUp"
                        data-wow-delay=".4s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.4s",
                          animationName: "fadeInUp",
                        }}
                      >
                        <span className="hollow-text">Our  </span>{" "}
                        <span className="featured-text">
                          {" "}
                          Digital Marketing 
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 500 150"
                            preserveAspectRatio="none"
                          >
                            <path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path>
                          </svg>
                        </span>{" "}
                        Services
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row gx-4 gy-4 services-row ">
                <div className="col-12 col-md-6  col-lg-6 mx-auto ">
                  <div
                    className="box service-box  wow fadeInUp "
                    data-wow-offset="0"
                    data-wow-delay=".2s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.2s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <span className="service-num hollow-text"></span>
                    <div className="service-content">
                      <h3 className="service-title">
                      Pay-Per-Click (PPC) Services{" "}
                      </h3>
                      <p className="service-text">
                      Xerotag offers effective PPC campaigns on Google, Facebook, Twitter, LinkedIn, driving targeted traffic and increasing visibility through cost per click strategies across various digital channels{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6  col-lg-6 mx-auto  ">
                  <div
                    className="box service-box  wow fadeInUp reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".3s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.3s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon"></div>
                    <span className="service-num hollow-text"></span>
                    <div className="service-content">
                      <h3 className="service-title">Content Marketing </h3>
                      <p className="service-text">
                      Xerotag delivers high-quality, SEO-driven content with in-depth keyword research, using blogs, infographics and e-books to build an audience and expand your online presence.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6  col-lg-6 mx-auto  ">
                  <div
                    className="box service-box  wow fadeInUp reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".4s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.4s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon"></div>
                    <span className="service-num hollow-text"></span>
                    <div className="service-content">
                      <h3 className="service-title">Digital PR </h3>
                      <p className="service-text">
                      Xerotag’s Digital PR services with influencer marketing, content marketing, and SEO increase your brand’s visibility, increase backlinks and create strong online brand awareness.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6  col-lg-6 mx-auto  ">
                  <div
                    className="box service-box  wow fadeInUp reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".5s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.5s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon"></div>
                    <span className="service-num hollow-text"> </span>
                    <div className="service-content">
                      <h3 className="service-title">Social Media Marketing</h3>
                      <p className="service-text">
                      Xerotag increases brand awareness through strategic social media marketing across Instagram, Facebook, LinkedIn and Twitter, generating leads, traffic and customer engagement{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6  col-lg-6 mx-auto  ">
                  <div
                    className="box service-box  wow fadeInUp  reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".6s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.6s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon"></div>
                    <span className="service-num hollow-text"></span>
                    <div className="service-content">
                      <h3 className="service-title">Influencer Marketing</h3>
                      <p className="service-text ">
                      Xerotag partners with top influencers to drive cost-effective campaigns, brand awareness, audience growth and conversions across digital platforms{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6  col-lg-6 mx-auto  ">
                  <div
                    className="box service-box  wow fadeInUp  reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".6s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.6s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon"></div>
                    <span className="service-num hollow-text"></span>
                    <div className="service-content">
                      <h3 className="service-title">Facebook Marketing Services</h3>
                      <p className="service-text ">
                      Xerotag’s Facebook marketing optimizes planning, product development, audience engagement, and research to grow your brand, reach new customers, and foster stronger relationships.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6  col-lg-6 mx-auto  ">
                  <div
                    className="box service-box  wow fadeInUp  reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".6s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.6s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon"></div>
                    <span className="service-num hollow-text"></span>
                    <div className="service-content">
                      <h3 className="service-title">Online Reputation Management (ORM) </h3>
                      <p className="service-text ">
                      Xerotag ensures a positive brand impression by managing and improving your online reputation, eliminating negative content, and encouraging positive results through an effective ORM process.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div>
          <section className="about mega-section">
            <div className="container">
              <div className="content-block  ">
                <div className="row">
                  <div
                    className="col-12 col-lg-12 d-flex align-items-center order-1 order-lg-0 about-col pad-end  wow fadeInUp"
                    data-wow-delay="0.6s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.6s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="text-area ">
                      <div className="sec-heading  light-title ">
                        <div className="content-area">
                          <h2
                            className="title    wow fadeInUp"
                            data-wow-delay=".4s"
                            style={{
                              visibility: "visible",
                              animationDelay: "0.4s",
                              animationName: "fadeInUp",
                            }}
                          >
                            <span className="hollow-text">Feature </span> of{" "}
                            <span className="featured-text">
                              {" "}
                              Digital Marketing 
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 500 150"
                                preserveAspectRatio="none"
                              >
                                <path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path>
                              </svg>
                            </span>{" "}
                            We Have
                          </h2>
                        </div>
                      </div>

                      <div className="info-items-list ">
                        <div className="row ">
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faBullseye} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Targeted Audience Reach{" "}
                                </h5>
                                <p className="info-text Webservice">
                                Xerotag’s digital marketing strategies allow you to connect with your exact target audience in a data-driven manner, ensuring your message reaches the right people at the right time.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faScaleBalanced} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Measurable Results{" "}
                                </h5>
                                <p className="info-text">
                                With Xerotag, you can track every aspect of your campaign. We provide comprehensive analytics to measure success from impression to click and conversion and optimize future strategies.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faSquarePollVertical} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">Cost-Effective Marketing</h5>
                                <p className="info-text">
                                Digital marketing at Xerotag offers cost-effective solutions for businesses of all sizes, ensuring maximum return on investment with flexible budgets on platforms such as Google, Facebook, and more.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faListUl} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">Personalized Marketing </h5>
                                <p className="info-text">
                                At Xerotag, our campaigns are tailored to your audience’s preferences and behaviors, ensuring a personalized experience that increases engagement, customer satisfaction and brand loyalty
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faDiceD20} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">Multi-Channel Engagement</h5>
                                <p className="info-text">
                                Xerotag uses a wide range of digital channels, including social media, email, search engines and content marketing, to deliver a seamless experience for your audience across multiple platforms
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faInfinity} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Real-Time Interaction
                                </h5>
                                <p className="info-text">
                                We help you connect with your audience in real time, creating opportunities for instant feedback, personalized offers, and timely communications that create stronger customer relationships.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <section className="about mega-section" id="about">
            <div className="container">
              <div className="content-block  ">
                <div className="row">
                  <div
                    className="col-12 col-lg-6 d-flex align-items-center about-col  wow fadeInUp"
                    data-wow-delay="0.2s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.2s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div
                      ref={(ref) => (tiltRefs.current[2] = ref)}
                      className="img-area  "
                      style={{
                        willChange: "transform",
                        transform:
                          "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                      }}
                    >
                      <div className="image  ">
                        <img
                          className="about-img img-fluid "
                          loading="lazy"
                          src={"/image/1.png"}
                          alt="about"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-12 col-lg-6 d-flex align-items-center about-col pad-start  wow fadeInUp"
                    data-wow-delay="0.6s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.6s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="text-area ">
                      <div className="sec-heading  light-title ">
                        <div className="content-area">
                          <h2
                            className="title    wow fadeInUp"
                            data-wow-delay=".4s"
                            style={{
                              visibility: "visible",
                              animationDelay: "0.4s",
                              animationName: "fadeInUp",
                            }}
                          >
                            Why <span className="hollow-text">Choose </span>{" "}
                            <span className="featured-text">
                              Xerotag{" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 500 150"
                                preserveAspectRatio="none"
                              >
                                <path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path>
                              </svg>
                            </span>{" "}
                            for Digital Marketing Company? 
                          </h2>
                        </div>
                      </div>
                      <div className="info-items-list">
                        <div className="row ">
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">01.</span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Expertise Across Industries
                                </h5>
                                <p className="info-text">
                                Xerotag has a proven track record of delivering successful digital marketing strategies across a variety of industries. Whether you’re in e-commerce, healthcare, or technology, we tailor our solutions to your specific needs.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">02.</span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Comprehensive Services
                                </h5>
                                <p className="info-text">
                                We offer a full range of digital marketing services, including SEO, PPC, social media management, content marketing, and more. Our all-in-one approach ensures your brand gets maximum visibility.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">03.</span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Data-Driven Strategies
                                </h5>
                                <p className="info-text">
                                At Xerotag, we rely on data analytics to make informed decisions that improve your marketing efforts. Our data-driven approaches promise outcome measurement and continuous improvement.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">04.</span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Customized Solutions
                                </h5>
                                <p className="info-text">
                                Every business is unique, and so are our strategies. We develop personalized digital marketing plans that align with your goals and target audience, ensuring maximum engagement and ROI.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">05.</span>
                              <div className="info-content">
                                <h5 className="info-title">Dedicated Support</h5>
                                <p className="info-text">
                                Our team of digital marketing professionals provide ongoing support, delivering real-time insights and flexibility to make your campaigns more efficient and effective.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">06.</span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Innovative Approach
                                </h5>
                                <p className="info-text">
                                We stay at the forefront of the latest trends and technologies in digital marketing, ensuring your business benefits from innovative strategies that keep you ahead of your competition.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cta-area ">
                        <div
                          className=" btn-solid "
                          onClick={() => scrollToSection("contact-us")}
                        >
                          get in touch
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        <ContactUsPg />
        <Footer />

    </div>
  )
}

export default DigitalMarketingService;
