import React from 'react'
import Homepg from './sections/Homepg'
import ServicePg from './sections/ServicePg'
import AboutUsPg from './sections/AboutUsPg'
import StatsPg from './sections/StatsPg'
// import PortfolioPg from './sections/PortfolioPg'
import ClientPg from './sections/ClientPg'
import TestimonialPg from './sections/TestimonialPg'
import FAQPg from './sections/FAQPg'
// import BlogPg from './sections/BlogPg'
import ContactUsPg from './sections/ContactUsPg'


const Section = () => {
  


  return (
    <div>
      <Homepg/>
      <ServicePg/>
      <AboutUsPg/>
      <StatsPg/>
      {/* <PortfolioPg/> */}
      <ClientPg/>
      <TestimonialPg/>
      <FAQPg/>
      {/* <BlogPg/> */}
      <ContactUsPg/>
    </div>
  )
}

export default Section
