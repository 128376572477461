// import React, { useState, useEffect } from 'react';

// function ModeSwitcher() {
//   const [theme, setTheme] = useState('dark');
//   useEffect(() => {
//     document.body.classList.add(theme === 'dark' ? 'dark-theme' : 'light-theme');
//   }, [theme]);

//   const handleToggleTheme = () => {
//     setTheme(theme === 'dark' ? 'light' : 'dark');
//   };

//   return (
//     <div className={`mode-switcher ${theme === 'dark' ? 'dark-theme' : 'light-theme'}`} onClick={handleToggleTheme}>
//         <div className="mode-switcher dark-theme d-flex flex-row">
//               <div className="switch-inner go-light " title="Switch To Light Mode "><i className="bi bi-sun icon "></i></div>
//               <div className="switch-inner go-dark" title="Switch To Dark Mode "><i className="bi bi-moon icon "></i></div>
//         </div>    
//     </div>
//   );
// }

// export default ModeSwitcher;

// import { createContext, useContext } from "react";

// export const pageBody = "body";
// export const modeSwitcher = "mode-switcher";
// export const themeStoredKey = "ThemeColor";
// export const darkTheme_class = "dark-theme";
// export const lightTheme_class = "light-theme";


// modeSwitcher.addClass(darkTheme_class);
// $(modeSwitcher).on("click", function () {
//   if ($(this).is("." + darkTheme_class)) {
//     pageBody.addClass(lightTheme_class).removeClass(darkTheme_class);
//     modeSwitcher.addClass(lightTheme_class).removeClass(darkTheme_class);
//   } else if ($(this).is("." + lightTheme_class)) {
//     pageBody.addClass(darkTheme_class).removeClass(lightTheme_class);
//     modeSwitcher.addClass(darkTheme_class).removeClass(lightTheme_class);
//   }
// });


import React, { useState, useEffect} from 'react';

const ModeSwitcher = () => {
  const [theme, setTheme] = useState(localStorage.getItem('ThemeColor') || 'light-theme');
  const themeStoredKey = 'ThemeColor';
  
  

  useEffect(() => {
    document.body.classList.remove('light-theme', 'dark-theme');
    document.body.classList.add(theme);
    localStorage.setItem('ThemeColor', theme); // Fix: pass theme as the second argument

  }, [theme]);

  const handleToggleTheme = () => {
    if (theme === 'dark-theme') {
      setTheme('light-theme');
      

    } else {
      setTheme('dark-theme');
    }
    localStorage.setItem(themeStoredKey, theme);
  };
  
  return (
    <div className={`mode-switcher ${theme} d-flex flex-row`} onClick={handleToggleTheme}>
        <div className="switch-inner go-light " title="Switch To Light Mode "><i className="bi bi-sun icon "></i></div> 
        <div className="switch-inner go-dark" title="Switch To Dark Mode "><i className="bi bi-moon icon "></i></div>
    </div>
    
  );
};

export default ModeSwitcher;

