import React from 'react'
import { useWowAnimation } from '../../hooks/useWowAnimation';

/* =========================   FAQ Section    =======================*/


const FAQPg = () => {
    useWowAnimation();

    return (
        // <!-- Start  faq Section-->
        <div>
            <section className="faq mega-section   " id="faq">
                <div className="shape-top-left"></div>
                <div className="shape-bottom-right"></div>
                <div className="pattern-top-end-dir"></div>
                <div className="pattern-bottom-start-dir"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-5 align-self-center">
                            <div className="faq-img start-dir-img-frame wow fadeIn" data-wow-delay="0.2s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeIn' }}><img className="img-fluid " loading="lazy" src={"image/faq-illustration.png"} alt="" /></div>
                        </div>
                        <div className="col-12 col-lg-7 ">
                            <div className="sec-heading  ">
                                <div className="content-area"><span className="pre-title       wow fadeInUp" data-wow-delay=".2s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp' }}>FAQs</span>
                                    <h2 className="title    wow fadeInUp" data-wow-delay=".4s" style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp' }}><span className="hollow-text">frequently </span> asked questions </h2>
                                </div>
                            </div>
                            {/* <!--Start Accordion List For FAQ--> */}

                            <div className="faq-accordion " id="accordion">
                                <div className="card mb-2">
                                    <div className="card-header " id="heading-1">
                                        <h5 className="mb-0 faq-title">
                                            <button className="btn btn-link faq-btn collapsed " data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="true" aria-controls="collapse-1">What services does Xerotag offer?</button>
                                        </h5>
                                    </div>
                                    <div className="collapse " id="collapse-1" aria-labelledby="collapse-1" data-bs-parent="#accordion">
                                        <div className="card-body">
                                            <p className="faq-answer"> Xerotag provides a comprehensive range of IT solutions, including digital transformation consulting, software development, quality assurance testing, and IT infrastructure management. We tailor our services to meet the unique needs of each client, ensuring a strategic approach to technology implementation. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mb-2">
                                    <div className="card-header " id="heading-2">
                                        <h5 className="mb-0 faq-title">
                                            <button className="btn btn-link  faq-btn  collapsed " data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="true" aria-controls="collapse-2">How does Xerotag ensure the quality of its IT solutions?</button>
                                        </h5>
                                    </div>
                                    <div className="collapse " id="collapse-2" aria-labelledby="collapse-2" data-bs-parent="#accordion">
                                        <div className="card-body">
                                            <p className="faq-answer">Quality is at the heart of everything we do. Our dedicated Quality Assurance team employs rigorous testing methodologies to ensure that all solutions meet the highest standards. We conduct thorough testing at every stage of development to identify and resolve issues early, ensuring a seamless and reliable user experience. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mb-2">
                                    <div className="card-header " id="heading-3">
                                        <h5 className="mb-0 faq-title">
                                            <button className="btn btn-link  faq-btn  collapsed " data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="true" aria-controls="collapse-3">Can Xerotag help small businesses with limited budgets?</button>
                                        </h5>
                                    </div>
                                    <div className="collapse " id="collapse-3" aria-labelledby="collapse-3" data-bs-parent="#accordion">
                                        <div className="card-body">
                                            <p className="faq-answer">Absolutely! We understand that small businesses have unique challenges and budget constraints. Xerotag offers flexible pricing and customized service packages to ensure that even small businesses can benefit from top-tier IT solutions without compromising on quality. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mb-2">
                                    <div className="card-header " id="heading-4">
                                        <h5 className="mb-0 faq-title">
                                            <button className="btn btn-link  faq-btn  collapsed " data-bs-toggle="collapse" data-bs-target="#collapse-4" aria-expanded="true" aria-controls="collapse-4">What industries does Xerotag specialize in?</button>
                                        </h5>
                                    </div>
                                    <div className="collapse " id="collapse-4" aria-labelledby="collapse-4" data-bs-parent="#accordion">
                                        <div className="card-body">
                                            <p className="faq-answer">Xerotag has extensive experience across various industries, including healthcare, finance, retail, and manufacturing. Our team of experts is well-versed in the specific technological needs and regulatory requirements of each sector, allowing us to deliver tailored solutions that drive success. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
    //   <!-- end faq Section-->
}
export default FAQPg;

