import React from "react";
import { useWowAnimation } from "../../hooks/useWowAnimation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCode,
  faLaptop,
  faLayerGroup,
  faMagnifyingGlass,
  faMobileScreen,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

/* =========================    Service Section    =======================*/

const ServicePg = () => {
  useWowAnimation();
  return (
    <div>
      <div>
        <div>
          <section
            className="services services-boxed mega-section"
            id="services"
          >
            <div className="container">
              <div className="sec-heading  ">
                <div className="content-area">
                  <span
                    className="pre-title wow fadeInUp"
                    data-wow-delay=".2s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.2s",
                      animationName: "fadeInUp",
                    }}
                  >
                    services
                  </span>
                  <h2
                    className="title wow fadeInUp"
                    data-wow-delay=".4s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.4s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <span className="hollow-text">services</span> we offer
                  </h2>
                  <p
                    className="subtitle wow fadeInUp"
                    data-wow-delay=".6s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.6s",
                      animationName: "fadeInUp",
                    }}
                  >
                    At Xerotag we pride ourselves on being more than just an IT
                    solutions provider,
                    <br /> we are your strategic partner in navigating the
                    digital landscape.{" "}
                  </p>
                </div>
                {/* <!--<div className="cta-area   wow fadeInUp" data-wow-delay=".8s" style="visibility: visible; animation-delay: 0.8s; animation-name: fadeInUp;"><a className="cta-btn btn-solid    ">see all services <i className="bi bi-arrow-right icon "></i></a></div>--> */}
              </div>
              <div className="row gx-4 gy-4 services-row ">
                <div className="col-12 col-md-6  col-lg-4 mx-auto ">
                  {/* <!--Start First service box--> */}
                  <div
                    className="box service-box  wow fadeInUp reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".1s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.1s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon">
                      <span className="font-icon">
                        <FontAwesomeIcon icon={faCode} />
                      </span>
                    </div>
                    <span className="service-num hollow-text">1</span>
                    <Link to='/WebdevService'>
                        <div className="service-content">
                        <h3 className="service-title">web development</h3>
                        <p className="service-text">
                            {" "}
                            Design and develop state-of-the-art, responsive websites
                            that ensure a compelling online presence and user
                            engagement.{" "}
                        </p>
                        </div>
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-md-6  col-lg-4 mx-auto ">
                  {/* <!--Start Second service box--> */}
                  <div
                    className="box service-box  wow fadeInUp reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".2s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.2s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon">
                      <span className="font-icon">
                        <FontAwesomeIcon icon={faLaptop} />
                      </span>
                    </div>
                    <span className="service-num hollow-text">2</span>
                    <Link to='/DigitalMarketingService'>
                    <div className="service-content">
                      <h3 className="service-title">Digital Marketing</h3>
                      <p className="service-text">
                        Our expert team crafts strategic campaigns that elevate
                        your brand's online presence, drive traffic, and engage
                        your audience across multiple platforms.{" "}
                      </p>
                    </div>
                    </Link>
                  </div>
                  {/* <!-- End Second service box--> */}
                </div>
                <div className="col-12 col-md-6  col-lg-4 mx-auto  ">
                  {/* <!--Start Third service box--> */}
                  <div
                    className="box service-box  wow fadeInUp reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".3s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.3s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon">
                      {/* <i className="flaticon-web-domain font-icon"></i> */}
                      <span className="font-icon">
                        <FontAwesomeIcon icon={faLayerGroup} />
                      </span>
                    </div>
                    <span className="service-num hollow-text">3</span>
                    <div className="service-content">
                      <h3 className="service-title">SaaS products </h3>
                      <p className="service-text">
                        Design and deploy tailored SaaS platforms that cater to
                        specific business needs, ensuring scalability, security,
                        and compliance.
                      </p>
                    </div>
                    {/* <!--<a className="read-more" href="https://xerotag.com/i=298372&amp;aff=entheos#0">read more<i className="bi bi-arrow-right icon "></i></a>--> */}
                  </div>
                  {/* <!-- End Third service box--> */}
                </div>
                <div className="col-12 col-md-6  col-lg-4 mx-auto  ">
                  {/* <!--Start fourth service box--> */}
                  <div
                    className="box service-box  wow fadeInUp reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".4s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.4s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon">
                      {/* <i className="flaticon-profile font-icon"></i> */}
                      <span className="font-icon">
                        <FontAwesomeIcon icon={faMobileScreen} />
                      </span>
                    </div>
                    <span className="service-num hollow-text">4</span>
                    <Link to='/AppdevService'>
                    <div className="service-content">
                      <h3 className="service-title">Apps development</h3>
                      <p className="service-text">
                        Create innovative mobile applications for iOS and
                        Android platforms, focusing on performance, security,
                        and user engagement to enhance your mobile strategy.
                      </p>
                    </div>
                    </Link>
                    {/* <!--<a className="read-more" href="https://xerotag.com/i=298372&amp;aff=entheos#0">read more<i className="bi bi-arrow-right icon "></i></a>--> */}
                  </div>
                  {/* <!-- End fourth service box   --> */}
                </div>
                <div className="col-12 col-md-6  col-lg-4 mx-auto  ">
                  {/* <!--Start 5th service box--> */}
                  <div
                    className="box service-box  wow fadeInUp reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".5s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.5s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon">
                      {/* <i className="flaticon-search font-icon"></i> */}
                      <span className="font-icon">
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </span>
                    </div>
                    <span className="service-num hollow-text">5 </span>
                    <Link to='/SEOService'>
                    <div className="service-content">
                      <h3 className="service-title">SEO services</h3>
                      <p className="service-text">
                        Enhance your online presence with our expert SEO
                        services designed to improve search engine rankings,
                        increase website traffic, and maximize digital marketing
                        ROI.{" "}
                      </p>
                    </div>
                    </Link>
                    {/* <!--<a className="read-more" href="https://xerotag.com/i=298372&amp;aff=entheos#0">read more<i className="bi bi-arrow-right icon "></i></a>--> */}
                  </div>
                  {/* <!-- End 5th service box--> */}
                </div>
                <div className="col-12 col-md-6  col-lg-4 mx-auto  ">
                  {/* <!--Start 6th service box--> */}
                  <div
                    className="box service-box  wow fadeInUp  reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".6s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.6s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon">
                      {/* <i className="flaticon-strategy font-icon"></i> */}
                      <span className="font-icon">
                        <FontAwesomeIcon icon={faPeopleGroup} />
                      </span>
                    </div>
                    <span className="service-num hollow-text">6</span>
                    <div className="service-content">
                      <h3 className="service-title"> Cloud Solutions</h3>
                      <p className="service-text">
                        Offer comprehensive cloud application development and
                        migration services to enhance your business scalability
                        and operational efficiency.{" "}
                      </p>
                    </div>
                    {/* <!--<a className="read-more" href="https://xerotag.com/i=298372&amp;aff=entheos#0">read more<i className="bi bi-arrow-right icon "></i></a>--> */}
                  </div>
                  {/* <!-- End 6th service box--> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
export default ServicePg;
