import React, { useEffect, useLayoutEffect, useRef } from 'react'
import ContactUsPg from './sections/ContactUsPg'
import Footer from './Footer'
import VanillaTilt from 'vanilla-tilt';
import Particles from 'react-particles-js';
import { customParticlesOptions } from '../main';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faClock, faCompress,  faDna,  faInfinity,   faRankingStar,  faUserTie } from '@fortawesome/free-solid-svg-icons';
import Header from "./Header ";

const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

const SEOService = () => {

    // const [showBackground, setShowBackground] = useState(false);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    const tiltRefs = useRef([]);
    useLayoutEffect(() => {
      tiltRefs.current.forEach((ref) => {
        if (ref) {
          VanillaTilt.init(ref, {
            max: 10, // Maximum tilt rotation (degrees)
            speed: 400, // Speed of the tilt effect
            scale: 1, // Scale factor on tilt
            glare: false, // Enable glare effect
            "max-glare": 0.5, // Maximum glare opacity
          });
        }
      });
    }, [tiltRefs]);
  
    useEffect(() => {
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        if (scrollPosition > 100) {
          // setShowBackground(true);
        } else {
          // setShowBackground(false);
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);


  return (
    <div>
      <Header/>
               {/* ----------------------particles----------------------- */}
      <div>
        <section
          className="page-hero services service-content  d-flex align-items-center reveal-start"
          id="page-hero"
        >
          <div className="particles-js dots" id="particles-js">
            <Particles
              params={{
                ...customParticlesOptions,
              }}
            />
          </div>
          {/* -------------------------home pg */}
          <div className="container">
            <div className="row align-items-center justify-content-center text-center ">
              <div className="col-12 col-lg-9">
                <div className="hero-text-area   ">
                  <div className="content-area ">
                    <h1
                      className="title    wow fadeInUp"
                      data-wow-delay=".4s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.4s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <span className="hollow-text ">SEO   </span>{" "}
                      <span className="featured-text">
                        {" "}
                        Company
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 500 150"
                          preserveAspectRatio="none"
                        >
                          <path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path>
                        </svg>
                      </span>{" "}
                    </h1>
                  </div>
                  <div className="col-11 ">
                    <p className="hero-subtitle text-center ">
                    We help increase your ROI by driving top results and attracting more customers on search engines like Google, Bing, Yahoo, Baidu, and more. Our customized SEO strategies increase your online visibility and grow your business.
                    </p>
                  </div>
                </div>
                <div
                  className=" btn-solid text-center "
                  onClick={() => scrollToSection("contact-us")}
                >
                  get in touch
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>

        <div>
          <section
            className="services services-boxed mega-section"
            id="services"
          >
            <div className="container">
              <div className="sec-heading  ">
                <div className="content-area">
                  <div>
                    <div className="content-area">
                      <h2
                        className="title    wow fadeInUp"
                        data-wow-delay=".4s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.4s",
                          animationName: "fadeInUp",
                        }}
                      >
                        <span className="hollow-text">SEO     </span>{" "}
                        <span className="featured-text">
                          {" "}
                           Services 
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 500 150"
                            preserveAspectRatio="none"
                          >
                            <path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path>
                          </svg>
                        </span>{" "}
                        We Offer 
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row gx-4 gy-4 services-row ">
                <div className="col-12 col-md-6  col-lg-6 mx-auto ">
                  <div
                    className="box service-box  wow fadeInUp "
                    data-wow-offset="0"
                    data-wow-delay=".2s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.2s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <span className="service-num hollow-text"></span>
                    <div className="service-content">
                      <h3 className="service-title">
                      SEO Website Analysis{" "}
                      </h3>
                      <p className="service-text">
                      We analyze the layout, speed and mobile friendliness of your website, identifying areas for improvement to drive effective SEO practices.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6  col-lg-6 mx-auto  ">
                  <div
                    className="box service-box  wow fadeInUp reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".3s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.3s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon"></div>
                    <span className="service-num hollow-text"></span>
                    <div className="service-content">
                      <h3 className="service-title">Keyword Research </h3>
                      <p className="service-text">
                      We identify the search terms we have targeted to attract engaged visitors with balanced competition and volume.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6  col-lg-6 mx-auto  ">
                  <div
                    className="box service-box  wow fadeInUp reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".4s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.4s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon"></div>
                    <span className="service-num hollow-text"></span>
                    <div className="service-content">
                      <h3 className="service-title">Competitive Analysis</h3>
                      <p className="service-text">
                      We analyze competitors’ SEO strategies, revealing opportunities to create a better strategy that positions your business as a leader.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6  col-lg-6 mx-auto  ">
                  <div
                    className="box service-box  wow fadeInUp reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".5s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.5s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon"></div>
                    <span className="service-num hollow-text"> </span>
                    <div className="service-content">
                      <h3 className="service-title">On-Page Optimization</h3>
                      <p className="service-text">
                      We optimize your site content, ensuring it is fully in line with SEO best practices to improve rankings.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6  col-lg-6 mx-auto  ">
                  <div
                    className="box service-box  wow fadeInUp  reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".6s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.6s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon"></div>
                    <span className="service-num hollow-text"></span>
                    <div className="service-content">
                      <h3 className="service-title">Building Authority</h3>
                      <p className="service-text ">
                      Our backlink system increases your site’s credibility, increases search engine power, and increases organic traffic.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6  col-lg-6 mx-auto  ">
                  <div
                    className="box service-box  wow fadeInUp  reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".6s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.6s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon"></div>
                    <span className="service-num hollow-text"></span>
                    <div className="service-content">
                      <h3 className="service-title">Link Building Strategy</h3>
                      <p className="service-text ">
                      Let us attract high quality backlinks through guest blogging and sharing to improve your site’s search rankings.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6  col-lg-6 mx-auto  ">
                  <div
                    className="box service-box  wow fadeInUp  reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".6s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.6s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon"></div>
                    <span className="service-num hollow-text"></span>
                    <div className="service-content">
                      <h3 className="service-title">Local SEO </h3>
                      <p className="service-text ">
                      We optimize your Google My Business profile and local directories, increasing visibility and quality for better local search results.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div>
          <section className="about mega-section">
            <div className="container">
              <div className="content-block  ">
                <div className="row">
                  <div
                    className="col-12 col-lg-12 d-flex align-items-center order-1 order-lg-0 about-col pad-end  wow fadeInUp"
                    data-wow-delay="0.6s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.6s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="text-area ">
                      <div className="sec-heading  light-title ">
                        <div className="content-area">
                          <h2
                            className="title    wow fadeInUp"
                            data-wow-delay=".4s"
                            style={{
                              visibility: "visible",
                              animationDelay: "0.4s",
                              animationName: "fadeInUp",
                            }}
                          >
                            <span className="hollow-text">Why Buy   </span> {" "}
                            <span className="featured-text">
                              {" "}
                              SEO Services   
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 500 150"
                                preserveAspectRatio="none"
                              >
                                <path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path>
                              </svg>
                            </span>{" "}
                            from Xerotag?
                          </h2>
                        </div>
                      </div>

                      <div className="info-items-list ">
                        <div className="row ">
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faDna} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Cost-Effective Solutions{" "}
                                </h5>
                                <p className="info-text Webservice">
                                At Xerotag, we offer high-quality SEO services at affordable prices. Our cost-effective approach ensures that small and medium-sized businesses can increase their online visibility without overextending their budget We deliver superior results that maximize your return on investment.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faUserTie} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Expert Team of SEO Professionals{" "}
                                </h5>
                                <p className="info-text">
                                Our team consists of seasoned and experienced SEO experts who stay up to date with the latest global SEO trends. With a track record of working with international clients, we ensure that your website is optimized to meet both local and global SEO standards.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faCompress} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">Comprehensive SEO Services</h5>
                                <p className="info-text">
                                Xerotag offers a full range of SEO services, including on-page optimization, off-page strategies, content marketing, technical SEO, and more. We are your one-stop solution for increasing organic traffic, improving search engine rankings, and increasing your brand’s online presence.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faClock} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">Round-the-Clock Flexibility</h5>
                                <p className="info-text">
                                With customers in different time zones, Xerotag offers extended support hours to ensure timely response and quick turnaround. We constantly monitor and optimize your website to stay ahead of the competition.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faCircleCheck} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">Proven SEO Success</h5>
                                <p className="info-text">
                                Our success stories speak for themselves. Xerotag has consistently helped businesses climb search engine rankings, improve their online visibility, drive organic growth and expand market share
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faRankingStar} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">
                                High-Quality Results
                                </h5>
                                <p className="info-text">
                                Quality is at the heart of everything we do at Xerotag. We adhere to best practices in SEO and maintain strict quality control procedures, ensuring that your SEO strategy delivers sustainable and measurable results.
                                </p>
                              </div>
                            </div>
                 
                        </div>
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faInfinity} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Industry-Specific Expertise
                                </h5>
                                <p className="info-text">
                                We have a wealth of experience working in a variety of industries. Whether you’re in e-commerce, healthcare, technology, or another sector, Xerotag brings valuable industry-specific insights to optimize your SEO strategy.
                                </p>
                              </div>
                            </div>
                 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </section>
        </div>

        <section className="about mega-section" id="about">
            <div className="container">
              <div className="content-block  ">
                <div className="row">
                  <div
                    className="col-12 col-lg-6 d-flex align-items-center about-col  wow fadeInUp"
                    data-wow-delay="0.2s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.2s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div
                      ref={(ref) => (tiltRefs.current[2] = ref)}
                      className="img-area  "
                      style={{
                        willChange: "transform",
                        transform:
                          "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                      }}
                    >
                      <div className="image  ">
                        <img
                          className="about-img img-fluid "
                          loading="lazy"
                          src={"/image/1.png"}
                          alt="about"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-12 col-lg-6 d-flex align-items-center about-col pad-start  wow fadeInUp"
                    data-wow-delay="0.6s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.6s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="text-area ">
                      <div className="sec-heading  light-title ">
                        <div className="content-area">
                          <h2
                            className="title    wow fadeInUp"
                            data-wow-delay=".4s"
                            style={{
                              visibility: "visible",
                              animationDelay: "0.4s",
                              animationName: "fadeInUp",
                            }}
                          >
                            Why <span className="hollow-text">Choose </span>{" "}
                            <span className="featured-text">
                              Xerotag{" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 500 150"
                                preserveAspectRatio="none"
                              >
                                <path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path>
                              </svg>
                            </span>{" "}
                            for Digital Marketing Company? 
                          </h2>
                        </div>
                      </div>
                      <div className="info-items-list">
                        <div className="row ">
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">01.</span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Expertise Across Industries
                                </h5>
                                <p className="info-text">
                                Xerotag has a proven track record of delivering successful digital marketing strategies across a variety of industries. Whether you’re in e-commerce, healthcare, or technology, we tailor our solutions to your specific needs.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">02.</span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Comprehensive Services
                                </h5>
                                <p className="info-text">
                                We offer a full range of digital marketing services, including SEO, PPC, social media management, content marketing, and more. Our all-in-one approach ensures your brand gets maximum visibility.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">03.</span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Data-Driven Strategies
                                </h5>
                                <p className="info-text">
                                At Xerotag, we rely on data analytics to make informed decisions that improve your marketing efforts. Our data-driven approaches promise outcome measurement and continuous improvement.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">04.</span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Customized Solutions
                                </h5>
                                <p className="info-text">
                                Every business is unique, and so are our strategies. We develop personalized digital marketing plans that align with your goals and target audience, ensuring maximum engagement and ROI.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">05.</span>
                              <div className="info-content">
                                <h5 className="info-title">Dedicated Support</h5>
                                <p className="info-text">
                                Our team of digital marketing professionals provide ongoing support, delivering real-time insights and flexibility to make your campaigns more efficient and effective.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">06.</span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Innovative Approach
                                </h5>
                                <p className="info-text">
                                We stay at the forefront of the latest trends and technologies in digital marketing, ensuring your business benefits from innovative strategies that keep you ahead of your competition.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cta-area ">
                        <div
                          className=" btn-solid "
                          onClick={() => scrollToSection("contact-us")}
                        >
                          get in touch
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        <ContactUsPg />
        <Footer />

    </div>
  )
}

export default SEOService
