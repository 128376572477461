import React, { useState, useEffect } from 'react';

const Loadingscr = () => {

  const [percentage, setPercentage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadingCounter = setInterval(() => {
      if (percentage <= 100) {
        setPercentage((prevPercentage) => prevPercentage + 1);
      } else {
        setIsLoading(false);
        clearInterval(loadingCounter);
      }
    }, 10);

    return () => clearInterval(loadingCounter);
  }, [percentage]);

  useEffect(() => {
    if (!isLoading) {
      const loadingScreen = document.getElementById('loading-screen');
      if (loadingScreen) {
        loadingScreen.style.opacity = 0;
        setTimeout(() => {
          loadingScreen.remove();
        }, 500);
      }
    }
  }, [isLoading]);

  return (
    <div id="loading-screen" className='loading-screen' style={{ opacity: 1 }}>
      <span className="bar top-bar" style={{ width: `${(100 - percentage) / 2}%` }}></span>
      <span className="bar down-bar" style={{ width: `${(100 - percentage) / 2}%` }}></span>
      <span className="progress-line" style={{ transform: `scale(${percentage / 100})`}}></span>
      <span className="loading-counter">{percentage}%</span>
    </div>
        // <div className="loading-screen" id="loading-screen">
        //     <span className="bar top-bar"></span>
        //     <span className="bar down-bar"></span>
        //     <span className="progress-line"></span>
        //     <span className="loading-counter"> </span>
        // </div>

  );
};

export default Loadingscr;