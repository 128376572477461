import React, { useLayoutEffect, useRef } from "react";
import { scrollToSection } from "../../main";
import VanillaTilt from "vanilla-tilt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward, faGlobe, faHeadset } from "@fortawesome/free-solid-svg-icons";
import { useWowAnimation } from "../../hooks/useWowAnimation";
/* =========================    About us Section    =======================*/

const AboutUsPg = () => {
  useWowAnimation();
  const tiltRefs = useRef([]);
  useLayoutEffect(() => {
    tiltRefs.current.forEach((ref) => {
      if (ref) {
        VanillaTilt.init(ref, {
          max: 10, // Maximum tilt rotation (degrees)
          speed: 400, // Speed of the tilt effect
          scale: 1, // Scale factor on tilt
          glare: false, // Enable glare effect
          "max-glare": 0.5, // Maximum glare opacity
        });
      }
    });
  }, [tiltRefs]);

  return (
    <div>
      <section className="about mega-section" id="about">
        <div className="container">
          {/* <!-- Start first about div--> */}
          <div className="content-block  ">
            <div className="row">
              <div
                className="col-12 col-lg-6 d-flex align-items-center order-1 order-lg-0 about-col pad-end  wow fadeInUp"
                data-wow-delay="0.6s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.6s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="text-area ">
                  <div className="sec-heading  light-title ">
                    <div className="content-area">
                      <span
                        className="pre-title       wow fadeInUp"
                        data-wow-delay=".2s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.2s",
                          animationName: "fadeInUp",
                        }}
                      >
                        about Us
                      </span>
                      <h2
                        className="title    wow fadeInUp"
                        data-wow-delay=".4s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.4s",
                          animationName: "fadeInUp",
                        }}
                      >
                        <span className="hollow-text">trusted</span> by{" "}
                        <span className="featured-text">
                          {" "}
                          worldwide
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 500 150"
                            preserveAspectRatio="none"
                          >
                            <path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path>
                          </svg>
                        </span>{" "}
                        clients
                      </h2>
                    </div>
                  </div>
                  <p className=" about-text">
                    Xerotag delivers innovative SaaS solutions and custom
                    software development, empowering businesses globally with
                    enhanced efficiency and scalability. Join the global
                    community that trusts Xerotag for cutting-edge technology
                    and exceptional service.
                  </p>
                  <div className="info-items-list ">
                    <div className="row ">
                      <div className="col-9 col-xl-6">
                        <div className="info-item">
                          <span className="info-icon">
                            <FontAwesomeIcon icon={faAward} />
                          </span>
                          <div className="info-content">
                            <h5 className="info-title">first on field </h5>
                            <p className="info-text">
                              Cutting-Edge Solutions | We're at the forefront of
                              innovation.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-9 col-xl-6">
                        <div className="info-item">
                          <i class="bi bi-controller info-icon"></i>
                          <div className="info-content">
                            <h5 className="info-title">easy to reach </h5>
                            <p className="info-text">
                              Seamless Communication | We're here whenever you
                              need us.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-9 col-xl-6">
                        <div className="info-item">
                          <span className="info-icon">
                            <FontAwesomeIcon icon={faGlobe} />
                          </span>
                          <div className="info-content">
                            <h5 className="info-title">worldwide services</h5>
                            <p className="info-text">
                              Global Expertise | Location no longer limits your
                              potential.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-9 col-xl-6">
                        <div className="info-item">
                          <span className="info-icon">
                            <FontAwesomeIcon icon={faHeadset} />
                          </span>
                          <div className="info-content">
                            <h5 className="info-title">24/7 support </h5>
                            <p className="info-text">
                              Unwavering Support | We're by your side, always.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cta-area">
                    <div
                      className=" btn-solid reveal-start"
                      onClick={() => scrollToSection("contact-us")}
                    >
                      Get in touch
                    </div>
                    <div className="signature ">
                      <div className="signature-img"></div>
                      <div className="signature-name"> </div>
                      {/* <!--CEO &amp; Founder --> */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-12 col-lg-6 d-flex align-items-center order-0 order-lg-1 about-col wow fadeInUp"
                data-wow-delay="0.2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInUp",
                }}
              >
                <div
                  ref={(ref) => (tiltRefs.current[0] = ref)}
                  className="img-area"
                  style={{
                    willChange: "transform",
                    transform:
                      "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                  }}
                >
                  <div className="image">
                    <img
                      className="about-img img-fluid"
                      loading="lazy"
                      src={"/image/3.png"}
                      alt="Our vision"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--End first about div-->
        <!-- Start first about div--> */}
          <div className="content-block  ">
            <div className="row">
              <div
                className="col-12 col-lg-6 d-flex align-items-center about-col  wow fadeInUp"
                data-wow-delay="0.2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInUp",
                }}
              >
                <div
                  ref={(ref) => (tiltRefs.current[1] = ref)}
                  className="img-area  "
                  style={{
                    willChange: "transform",
                    transform:
                      "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                  }}
                >
                  <div className="image  ">
                    <img
                      className="about-img img-fluid "
                      loading="lazy"
                      src={"/image/1.png"}
                      alt="about"
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-12 col-lg-6 d-flex align-items-center about-col pad-start  wow fadeInUp"
                data-wow-delay="0.6s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.6s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="text-area ">
                  <div className="sec-heading  light-title ">
                    <div className="content-area">
                      <span
                        className="pre-title       wow fadeInUp"
                        data-wow-delay=".2s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.2s",
                          animationName: "fadeInUp",
                        }}
                      >
                        why choose us
                      </span>
                      <h2
                        className="title    wow fadeInUp"
                        data-wow-delay=".4s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.4s",
                          animationName: "fadeInUp",
                        }}
                      >
                        Why our <span className="hollow-text"> customers</span>{" "}
                        choose{" "}
                        <span className="featured-text">
                          working{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 500 150"
                            preserveAspectRatio="none"
                          >
                            <path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path>
                          </svg>
                        </span>{" "}
                        with us
                      </h2>
                    </div>
                  </div>
                  <p className=" about-text">
                    In today's competitive landscape, innovative technology and
                    strategic approaches are crucial for success. At Xerotag, we
                    go beyond basic development to deliver exceptional value to
                    our clients.
                  </p>
                  <div className="info-items-list">
                    <div className="row ">
                      <div className="col-12 ">
                        <div className="info-item">
                          <span className="info-number ">01.</span>
                          <div className="info-content">
                            <h5 className="info-title">latest technologies </h5>
                            <p className="info-text">
                              We stay at the forefront of the ever-evolving tech
                              world. Our team actively researches and integrates
                              the latest technologies into your web and mobile
                              solutions, ensuring they are not only functional
                              but cutting-edge. This translates to a competitive
                              advantage for your business, allowing you to
                              leverage the power of the newest advancements.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 ">
                        <div className="info-item">
                          <span className="info-number ">02.</span>
                          <div className="info-content">
                            <h5 className="info-title">uniqe solutions </h5>
                            <p className="info-text">
                              We understand that a one-size-fits-all approach
                              doesn't work. We take the time to understand your
                              specific needs and challenges. Our team then
                              crafts unique solutions that perfectly match your
                              business objectives and target audience. This
                              ensures your web and mobile applications are not
                              just generic products, but powerful tools that
                              drive growth and success.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 ">
                        <div className="info-item">
                          <span className="info-number ">03.</span>
                          <div className="info-content">
                            <h5 className="info-title">powerful strategies </h5>
                            <p className="info-text">
                              Technology alone isn't enough. We combine our
                              expertise with proven strategic thinking to create
                              a comprehensive roadmap for your digital success.
                              We don't just build applications; we develop
                              strategies that leverage data, user behavior, and
                              market trends to maximize the impact of your web
                              and mobile presence.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cta-area ">
                    <div
                      className=" btn-solid "
                      onClick={() => scrollToSection("contact-us")}
                    >
                      get in touch
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--End first about div--> */}
        </div>
      </section>
    </div>
  );
};

export default AboutUsPg;
