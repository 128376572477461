import React from 'react'
import Header from './Header '
import Section from './Section'
import Footer from './Footer'
import Loadingscr from "../components/Loadingscr";

const Body = () => {

  return (
    <div  > 
      <Header />
      <Section />
      <Footer />
      <Loadingscr/>
    </div>
  )
}

export default Body
