import React, { useEffect, useRef } from 'react'
import Swiper from 'swiper';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useWowAnimation } from '../../hooks/useWowAnimation';

/* =========================   testimonials Section    =======================*/

const TestimonialPg = () => {
    useWowAnimation();
        const swiperRef = useRef(null);
    
    useEffect(() => {
        const swiper = new Swiper(swiperRef.current, {
        speed: 600,
        slidesPerView: 1,
        spaceBetween: 50,
        delay: 7000,
        autoplay :{
            delay: 7000,
        },        
        pagination: false,
        loop: true,
        navigation: {
            nextEl: ".testimonials-1-col .swiper-button-next",
            prevEl: ".testimonials-1-col .swiper-button-prev",
        },
        modules: [Autoplay, Pagination, Navigation],

    });
    swiper.init();
    }, []);

  return (
    // <!-- Start  testimonials Section--> 
    <div>
        <section className="testimonials testimonials-1-col   has-dark-bg  mega-section " id="testimonials">
            <div className="container">
                <div className="sec-heading  centered ">
                    <div className="content-area"><span className="pre-title       wow fadeInUp" data-wow-delay=".2s" style={{visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp'}}>testimonials</span>
                        <h2 className="title    wow fadeInUp" data-wow-delay=".4s" style={{visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp'}}>customers <span className="hollow-text">testmonials</span></h2>
                    </div>
                </div>
                <div className="row d-flex align-items-center">
                    <div className="col-12 col-md-10  mx-auto">
                        <div ref={swiperRef} className="swiper-container wow fadeInUp swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events"  data-wow-delay="0.2s" style={{visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp',cursor: 'grab'}}>
                            <div className="swiper-wrapper"    id="swiper-wrapper-aaff14b64f5380ae" aria-live="off" style={{transitionDuration: '0ms', transform: 'translate3d(-2252px, 0px, 0px)'}}>
                                <div  className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next" data-swiper-slide-index="2" role="group" aria-label="1 / 5" style={{width: '1076px', marginRight: '50px'}} >
                                    <div className="testmonial-card d-flex align-items-center justify-content-center">
                                        <div className="testimonial-content">
                                            <div className="customer-img "><img className="img-fluid " loading="lazy" src={"/image/4.png"} alt="First Slide "/>
                                            </div>
                                            <div className="customer-testimonial">
                                                <div className="content">
                                                    <p className="testimonial-text "> Xerotag transformed our digital presence with their innovative IT solutions. Their strategic partnership approach helped us navigate complex technological challenges and seize new opportunities. The result? A 30% increase in our online engagement and a seamless user experience across all platforms.            </p>
                                                </div>
                                            </div>
                                            <div className="customer-info ">
                                                <div className="customer-details">
                                                    <p className="customer-name">Kiran Kooner</p>
                                                    <p className="customer-role">Director</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--First Slide--> */}
                                <div className="swiper-slide swiper-slide-prev" data-swiper-slide-index="0" role="group" aria-label="2 / 5" style={{width: '1076px', marginRight: '50px'}}>
                                    <div className="testmonial-card d-flex align-items-center justify-content-center">
                                        <div className="testimonial-content">
                                            <div className="customer-img "><img className="img-fluid " loading="lazy" src={"/image/1(1).png"} alt="First Slide "/>
                                            </div>
                                            <div className="customer-testimonial">
                                                <div className="content">
                                                    <p className="testimonial-text "> The Quality Assurance team at Xerotag is second to none. Their rigorous testing methodologies uncovered and resolved issues we didn't even know existed. Thanks to their meticulous attention to detail, our app launch was flawless, and customer satisfaction scores have never been higher.</p>
                                                </div>
                                            </div>
                                            <div className="customer-info ">
                                                <div className="customer-details">
                                                    <p className="customer-name">John Bawak</p>
                                                    <p className="customer-role">founder</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--Second Slide--> */}
                                <div className="swiper-slide swiper-slide-active" data-swiper-slide-index="1" role="group" aria-label="3 / 5" style={{width: '1076px', marginRight: '50px'}}>
                                    <div className="testmonial-card d-flex align-items-center justify-content-center">
                                        <div className="testimonial-content">
                                            <div className="customer-img "><img className="img-fluid " loading="lazy" src={"/image/2.png"} alt="First Slide "/></div>
                                            <div className="customer-testimonial">
                                                <div className="content">
                                                    <p className="testimonial-text "> As a small business owner, I was worried about the cost of top-tier IT solutions. Xerotag surprised me with their transparent and competitive pricing. They tailored a package that fit our needs and budget perfectly, proving that quality doesn't have to break the bank.</p>
                                                </div>
                                            </div>
                                            <div className="customer-info ">
                                                <div className="customer-details">
                                                    <p className="customer-name">Manoj Sonecha</p>
                                                    <p className="customer-role">Director</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--third Slide--> */}
                                <div className="swiper-slide swiper-slide-next" data-swiper-slide-index="2" role="group" aria-label="4 / 5" style={{width: '1076px', marginRight: '50px'}}>
                                    <div className="testmonial-card d-flex align-items-center justify-content-center">
                                        <div className="testimonial-content">
                                            <div className="customer-img "><img className="img-fluid " loading="lazy" src={"/image/5.png"} alt="First Slide "/></div>
                                            <div className="customer-testimonial">
                                                <div className="content">
                                                    <p className="testimonial-text "> Xerotag's expertise in navigating the digital landscape was a game-changer for our company. Their insights into emerging technologies helped us stay ahead of the curve. We've seen a significant ROI on our IT investments since partnering with them.            </p>
                                                </div>
                                            </div>
                                            <div className="customer-info ">
                                                <div className="customer-details">
                                                    <p className="customer-name">Kashmir Rana</p>
                                                    <p className="customer-role">manager</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="0" role="group" aria-label="5 / 5" style={{width: '1076px', marginRight: '50px'}}>
                                    <div className="testmonial-card d-flex align-items-center justify-content-center">
                                        <div className="testimonial-content">
                                            <div className="customer-img "><img className="img-fluid " loading="lazy" src={"/image/1(1).png"} alt="First Slide "/></div>
                                            <div className="customer-testimonial">
                                                <div className="content">
                                                    <p className="testimonial-text "> What sets Xerotag apart is their commitment to being more than just a service provider. They truly became our strategic partner, understanding our business goals and aligning their IT solutions accordingly. Their support has been instrumental in our company's growth and success in the digital realm.</p>
                                                </div>
                                            </div>
                                            <div className="customer-info ">
                                                <div className="customer-details">
                                                    <p className="customer-name">Bhavik</p>
                                                    <p className="customer-role">founder</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--navigation buttons--> */}
                            <div className="swiper-button-prev" tabIndex="0" role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-aaff14b64f5380ae">
                                <div className="left-arrow"><i className="bi bi-chevron-left icon "></i>
                                </div>
                            </div>
                            <div className="swiper-button-next" tabIndex="0" role="button" aria-label="Next slide" aria-controls="swiper-wrapper-aaff14b64f5380ae">
                                <div className="right-arrow"><i className="bi bi-chevron-right icon "></i>
                                </div>
                            </div>
                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
        // <!-- End  testimonials Section-->
  )
}
export default TestimonialPg;

