import React, { useState, useEffect } from 'react';

const Counter = ({ initialCount, endCount, speed, className }) => {
  const [count, setCount] = useState(initialCount);

  useEffect(() => {
    let startTime = null;
    const intervalId = setInterval(() => {
      if (startTime === null) {
        startTime = Date.now();
      }
      const elapsed = Date.now() - startTime;
      const progress = Math.min(elapsed / speed, 1);
      const currentCount = Math.floor(initialCount + (endCount - initialCount) * progress);
      setCount(currentCount);
      if (progress === 1) {
        clearInterval(intervalId);
      }
    }, 16); // 16 milliseconds = 60 frames per second
    return () => clearInterval(intervalId);
  }, [initialCount, endCount, speed]);

  return (
 
        <span className={className}>{count}</span>
  );
};

export default Counter;