import React, { useLayoutEffect, useRef } from 'react'
import VanillaTilt from 'vanilla-tilt';
import Counter from '../Counter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrosshairs, faFaceSmile, faListCheck, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
/* =========================    Stats Section    =======================*/

const StatsPg = () => {

  const tiltRefs = useRef([]);
  useLayoutEffect(() => {
    tiltRefs.current.forEach((ref) => {
      if (ref) {
        VanillaTilt.init(ref, {
          max: 10, // Maximum tilt rotation (degrees)
          speed: 400, // Speed of the tilt effect
          scale: 1, // Scale factor on tilt
          glare: false, // Enable glare effect
          'max-glare': 0.5 // Maximum glare opacity
        });
      }
    });
  }, [tiltRefs]);
  const tiltReferences = [useRef(null), useRef(null), useRef(null), useRef(null)];




  return (
    <div>
      {/* <!-- Start  stats Section--> */}
      <section className="stats js-stats-counter mega-section">
        <div className="overlay-photo-image-bg" /*data-bg-img="assets/images/sections-bg-images/pattern-bg-3.jpg"*/ data-bg-opacity=".2" style={{ backgroundImage: 'url(&quot;assets/images/sections-bg-images/pattern-bg-3.jpg&quot;)', opacity: '0.2' }}></div>
        <div className="container">
          <div className="stats-inner">
            <div className="row ">
              {/* <!--Info One--> */}
              <div className="col-12 col-md-6 col-lg-3 stat-box ">
                <div className="stat-box-inner  " ref={(ref) => (tiltRefs.current[0] = ref)} >
                  {/* <i className="flaticon-project-management stat-icon"></i> */}
                  <span className='stat-icon'>
                    <FontAwesomeIcon icon={faListCheck} />
                  </span>
                  <p className="stat-num ">
                    <Counter initialCount={10} endCount={750} speed={3000} className="counter" />
                    <span className="sign">+</span>
                  </p>
                  <span className="stat-desc">finished projects</span>
                </div>
              </div>
              {/* <!--Info Two--> */}
              <div className="col-12 col-md-6 col-lg-3 stat-box ">
                <div className="stat-box-inner  " ref={(ref) => (tiltRefs.current[1] = ref)} >
                  {/* <i className="flaticon-content-management stat-icon"></i> */}
                  <span className='stat-icon'>
                    <FontAwesomeIcon icon={faPeopleGroup} />
                  </span>
                  <p className="stat-num ">
                    <Counter initialCount={0} endCount={23} speed={3000} className="counter" />
                    <span className="sign">+</span>
                  </p>
                  <span className="stat-desc">Created jobs</span>
                </div>
              </div>
              {/* <!--Info Three--> */}
              <div className="col-12 col-md-6 col-lg-3 stat-box ">
                <div className="stat-box-inner  " ref={(ref) => (tiltRefs.current[2] = ref)} style={{ willChange: 'transform', transform: 'perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)' }}>
                  {/* <i className="flaticon-user stat-icon"></i> */}
                  <span className='stat-icon'>
                    <FontAwesomeIcon icon={faFaceSmile} />
                  </span>
                  <p className="stat-num ">
                    <Counter initialCount={0} endCount={200} speed={3000} className="counter" />
                    <span className="sign">+</span>
                  </p>
                  <span className="stat-desc">happy customers</span>
                </div>
              </div>
              {/* <!--Info Four--> */}
              <div className="col-12 col-md-6 col-lg-3 stat-box ">
                <div className="stat-box-inner  " ref={(ref) => (tiltRefs.current[3] = ref)} style={{ willChange: 'transform', transform: 'perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)' }}>
                  {/* <i className="flaticon-aim stat-icon"></i>*/}
                  <span className='stat-icon'>
                    <FontAwesomeIcon icon={faCrosshairs} />
                  </span>
                  <p className="stat-num ">
                    <Counter initialCount={0} endCount={28} speed={3000} className="counter" />
                    <span className="sign">+</span>
                  </p>
                  <span className="stat-desc">years Of exprience</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End  stats Section--> */}
    </div>
  )
}
export default StatsPg;
