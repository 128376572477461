import React, { useState, useEffect } from 'react';
import ModeSwitcher from './ModeSwitcher';
import { scrollToSection } from '../main';
import '../css/header.css'; // Use relative path
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const [showBackground, setShowBackground] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  // const isServicesPage = window?.location?.pathname === '/web-development';
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setShowBackground(scrollPosition > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleServicesClick = () => {
    setIsSubmenuOpen(!isSubmenuOpen);
  };

  return (
      <header className={`header-basic ${showBackground ? 'is-sticky' : ''}`} id="page-header">
        <div className="container">
          <nav className="menu-navbar d-flex flex-row" id="main-nav">
            <div className="header-logo">
              <a className="logo-link" href="/">
                <img className="logo-img dark-logo" loading="lazy" src="/image/logo-dark.png" alt="logo" />
                <img className="logo-img light-logo" loading="lazy" src="/image/logo-light.png" alt="logo" />
              </a>
            </div>
            <div className={`links menu-wrapper ${isMenuOpen ? 'show' : ''}`}>
              <ul className="list-js links-list">
                <li className="nav-item menu-item has-sub-menu">
                  <div className="nav-link menu-link" onClick={() => scrollToSection('page-hero')}>Home</div>
                </li>
                <li className="nav-item menu-item has-sub-menu">
                  <div className="nav-link menu-link" onClick={handleServicesClick}>Services</div>
                  {isSubmenuOpen && (
                      <ul className="submenu">
                        <li className="submenu-item" onClick={() => navigate('/web-development')}>Web Development</li>
                        <li className="submenu-item" onClick={() => navigate('/seo')}>SEO Services</li>
                        <li className="submenu-item" onClick={() => navigate('/digital-marketing')}>Digital Marketing</li>
                        <li className="submenu-item" onClick={() => navigate('/app-development')}>App Development</li>
                      </ul>
                  )}
                </li>
                <li className="nav-item menu-item">
                  <div className="nav-link menu-link" onClick={() => scrollToSection('about')}>About Us</div>
                </li>
                <li className="nav-item menu-item">
                  <div className="nav-link menu-link" onClick={() => scrollToSection('testimonials')}>Testimonials</div>
                </li>
                <li className="nav-item menu-item">
                  <div className="nav-link menu-link" onClick={() => scrollToSection('faq')}>FAQ</div>
                </li>
                <li className="nav-item menu-item">
                  <div className="nav-link menu-link" onClick={() => scrollToSection('contact-us')}>Contact Us</div>
                </li>
              </ul>
            </div>
            <div className="controls-box">
              <div className={`control menu-toggler ${isMenuOpen ? 'close-menu' : ''}`} onClick={handleMenuToggle}>
                <span></span><span></span><span></span>
              </div>
              <ModeSwitcher />
            </div>
          </nav>
        </div>
      </header>
  );
}

export default Header;
