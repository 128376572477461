import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { customParticlesOptions } from '../main'
import ContactUsPg from './sections/ContactUsPg'
import Footer from './Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCube, faGear, faInfinity, faListUl, faLocationDot, faSpinner } from '@fortawesome/free-solid-svg-icons'
import Particles from 'react-particles-js'
import VanillaTilt from 'vanilla-tilt'
import Header from "./Header ";

const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
  }
};

const AppDevService = () => {

  const [showBackground, setShowBackground] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tiltRefs = useRef([]);
  useLayoutEffect(() => {
    tiltRefs.current.forEach((ref) => {
      if (ref) {
        VanillaTilt.init(ref, {
          max: 10, // Maximum tilt rotation (degrees)
          speed: 400, // Speed of the tilt effect
          scale: 1, // Scale factor on tilt
          glare: false, // Enable glare effect
          "max-glare": 0.5, // Maximum glare opacity
        });
      }
    });
  }, [tiltRefs]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        setShowBackground(true);
      } else {
        setShowBackground(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
    {/* --------------------------Header---------------------------------- */}
    <Header/>
       <div>
        <section
          className="page-hero services service-content  d-flex align-items-center reveal-start"
          id="page-hero"
        >
          <div className="particles-js dots" id="particles-js">
            <Particles
              params={{
                ...customParticlesOptions,
              }}
            />
          </div>
          {/* -------------------------home pg */}
          <div className="container">
            <div className="row align-items-center justify-content-center text-center ">
              <div className="col-12 col-lg-9">
                <div className="hero-text-area   ">
                  <div className="content-area ">
                    <h1
                      className="title    wow fadeInUp"
                      data-wow-delay=".4s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.4s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <span className="hollow-text ">Custom Mobile  </span>{" "}
                      <span className="featured-text">
                        {" "}
                        App Development
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 500 150"
                          preserveAspectRatio="none"
                        >
                          <path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path>
                        </svg>
                      </span>{" "}
                      Company
                    </h1>
                  </div>
                  <div className="col-11 ">
                    <p className="hero-subtitle text-center ">
                    Expand your reach by connecting with customers on their preferred platforms. Choose Xerotag as your custom mobile app development partner, and accelerate your business growth faster.
                    </p>
                  </div>
                </div>
                <div
                  className=" btn-solid text-center "
                  onClick={() => scrollToSection("contact-us")}
                >
                  get in touch
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* -------------------------(start) Out web dev services------------------------ */}
        <div>
          <section
            className="services services-boxed mega-section"
            id="services"
          >
            <div className="container">
              <div className="sec-heading  ">
                <div className="content-area">
                  <div>
                    <div className="content-area">
                      <h2
                        className="title    wow fadeInUp"
                        data-wow-delay=".4s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.4s",
                          animationName: "fadeInUp",
                        }}
                      >
                        <span className="hollow-text">Our Mobile  </span>{" "}
                        <span className="featured-text">
                          {" "}
                          App Development
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 500 150"
                            preserveAspectRatio="none"
                          >
                            <path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path>
                          </svg>
                        </span>{" "}
                        Services
                      </h2>
                    </div>
                    <p
                      className="subtitle wow fadeInUp"
                      data-wow-delay=".6s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.6s",
                        animationName: "fadeInUp",
                      }}
                    >
                      Xerotag offers end-to-end mobile application development, from building custom apps<br /> to enhancing existing ones, ensuring compatibility with the latest technological<br /> advances at every stage of development.
                      {" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="row gx-4 gy-4 services-row ">
                <div className="col-12 col-md-6  col-lg-6 mx-auto ">
                  <div
                    className="box service-box  wow fadeInUp "
                    data-wow-offset="0"
                    data-wow-delay=".2s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.2s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <span className="service-num hollow-text"></span>
                    <div className="service-content">
                      <h3 className="service-title">
                      Android App Development {" "}
                      </h3>
                      <p className="service-text">
                      Xerotag’s Android app development uses modern tools such as Kotlin, Java, and Jetpack Compose to create or redesign custom Android apps, delivering high-quality solutions for seamless business operations across devices{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6  col-lg-6 mx-auto  ">
                  <div
                    className="box service-box  wow fadeInUp reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".3s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.3s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon"></div>
                    <span className="service-num hollow-text"></span>
                    <div className="service-content">
                      <h3 className="service-title">iOS App Development  </h3>
                      <p className="service-text">
                      Xerotag delivers robust native iOS apps that meet the highest standards. Our expert team of Swift, SwiftUI, and Objective-C developers build award-winning apps for iPhone, iPad, Apple Watch, and Apple TV.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6  col-lg-6 mx-auto  ">
                  <div
                    className="box service-box  wow fadeInUp reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".4s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.4s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon"></div>
                    <span className="service-num hollow-text"></span>
                    <div className="service-content">
                      <h3 className="service-title">React Native App Development</h3>
                      <p className="service-text">
                      Xerotag React Native developers build high-performance mobile apps with robust integrations. Our expertise has helped clients reach the top of the iOS App Store and Google Play Store charts.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6  col-lg-6 mx-auto  ">
                  <div
                    className="box service-box  wow fadeInUp reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".5s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.5s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon"></div>
                    <span className="service-num hollow-text">5 </span>
                    <div className="service-content">
                      <h3 className="service-title">Flutter App Development</h3>
                      <p className="service-text">
                      Xerotag’s Flutter developers use Flutter widgets and Dart to create stunning, high-performance apps for iOS and Android. With strong community support, Flutter is a top choice for global app development.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6  col-lg-6 mx-auto  ">
                  <div
                    className="box service-box  wow fadeInUp  reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".6s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.6s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon"></div>
                    <span className="service-num hollow-text">6</span>
                    <div className="service-content">
                      <h3 className="service-title"> Swift App Development</h3>
                      <p className="service-text">
                      Xerotag iOS developers use Swift and SwiftUI to create high-quality apps for iPhones, iPads, and Apple Watch. We provide end-to-end iOS development, ensuring Apple guidelines are followed.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6  col-lg-6 mx-auto  ">
                  <div
                    className="box service-box  wow fadeInUp  reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".6s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.6s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon"></div>
                    <span className="service-num hollow-text">6</span>
                    <div className="service-content">
                      <h3 className="service-title">Kotlin App Development</h3>
                      <p className="service-text ">
                      Xerotag’s expert Kotlin developers bring your ideas to life, using their advanced Kotlin development skills to create feature-rich Android applications to deliver a unique user experience{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* -----------------------------Features of webdev we have----------------------------------- */}
        <div>
          <section className="about mega-section">
            <div className="container">
              <div className="content-block  ">
                <div className="row">
                  <div
                    className="col-12 col-lg-12 d-flex align-items-center order-1 order-lg-0 about-col pad-end  wow fadeInUp"
                    data-wow-delay="0.6s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.6s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="text-area ">
                      <div className="sec-heading  light-title ">
                        <div className="content-area">
                          <h2
                            className="title    wow fadeInUp"
                            data-wow-delay=".4s"
                            style={{
                              visibility: "visible",
                              animationDelay: "0.4s",
                              animationName: "fadeInUp",
                            }}
                          >
                            <span className="hollow-text">Feature </span> of Mobile {" "}
                            <span className="featured-text">
                              {" "}
                              App Development
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 500 150"
                                preserveAspectRatio="none"
                              >
                                <path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path>
                              </svg>
                            </span>{" "}
                            We Have
                          </h2>
                        </div>
                      </div>

                      <div className="info-items-list ">
                        <div className="row ">
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faGear} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Custom App Design{" "}
                                </h5>
                                <p className="info-text Webservice">
                                We create visually stunning and user-friendly design that matches your brand, and ensure a smooth user experience across all devices.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faSpinner} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Cross-Platform Development{" "}
                                </h5>
                                <p className="info-text">
                                Our expertise in technologies like Flutter and React Native allows us to build apps that work seamlessly on both iOS and Android, making it more accessible to you.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faLocationDot} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">High Performance</h5>
                                <p className="info-text">
                                We optimize each app for speed and performance, ensuring performance and delivering fast loading times to keep users engaged.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faListUl} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">Scalable Solutions</h5>
                                <p className="info-text">
                                Our apps are designed to grow with your business, allowing for seamless updates and additional integrations as your needs evolve.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faCube} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">Robust Security</h5>
                                <p className="info-text">
                                We use extensive security measures to protect user data and ensure that your app meets the highest privacy and compliance standards.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faInfinity} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Ongoing Support
                                </h5>
                                <p className="info-text">
                                In addition to development, we provide ongoing support and maintenance to keep your app up-to-date and fully functional as technology advances.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* -------------------------(start) WEbDev technologies we are expert in -------------------------------- */}
        <div>
          <section>
            <div className="container">
              <div
                className="col-12 col-lg-10 d-flex align-items-center order-1 order-lg-0 about-col pad-end  wow fadeInUp"
                data-wow-delay="0.6s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.6s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="sec-heading  light-title ">
                  <div className="content-area">
                    <h2
                      className="title    wow fadeInUp"
                      data-wow-delay=".4s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.4s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <span className="hollow-text">Mobile App Development </span>{" "}
                      <span className="featured-text">
                        {" "}
                        Technologies
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 500 150"
                          preserveAspectRatio="none"
                        >
                          <path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path>
                        </svg>
                      </span>{" "}
                      We Are Experts In
                    </h2>
                  </div>
                </div>
              </div>

              <div>
                <div
                  className="our-technologies big-section wow fadeInUp"
                  data-wow-delay="0.2s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.2s",
                    animationName: "fadeInUp",
                  }}
                >
                  <div className="tech-logos d-flex align-items-center justify-content-around flex-wrap">
                    <div className="tech-logos ">
                      <div
                        className="technology-logo  "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/javascript.png"}
                          alt="saffronIvy "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/dart.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/csharp.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/java.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{
                          width: "160px",
                          marginRight: "30px",
                          alignContent: "center",
                        }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/python.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/ruby.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{
                          width: "160px",
                          marginRight: "30px",
                          alignContent: "center",
                        }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/reactnative.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{
                          width: "160px",
                          marginRight: "30px",
                          alignContent: "center",
                        }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/apache.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo  "
                          loading="lazy"
                          src={"/image/nativescript.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/appcelerator.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/flutter.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/xamrin.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/codename.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/swift.png"}
                          alt=" "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div>
          {/* -------------------------------------(start) Why choose us page--------------------------- */}
          <section className="about mega-section" id="about">
            <div className="container">
              <div className="content-block  ">
                <div className="row">
                  <div
                    className="col-12 col-lg-6 d-flex align-items-center about-col  wow fadeInUp"
                    data-wow-delay="0.2s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.2s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div
                      ref={(ref) => (tiltRefs.current[2] = ref)}
                      className="img-area  "
                      style={{
                        willChange: "transform",
                        transform:
                          "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                      }}
                    >
                      <div className="image  ">
                        <img
                          className="about-img img-fluid "
                          loading="lazy"
                          src={"/image/1.png"}
                          alt="about"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-12 col-lg-6 d-flex align-items-center about-col pad-start  wow fadeInUp"
                    data-wow-delay="0.6s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.6s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="text-area ">
                      <div className="sec-heading  light-title ">
                        <div className="content-area">
                          <h2
                            className="title    wow fadeInUp"
                            data-wow-delay=".4s"
                            style={{
                              visibility: "visible",
                              animationDelay: "0.4s",
                              animationName: "fadeInUp",
                            }}
                          >
                            Why <span className="hollow-text">Choose </span>{" "}
                            <span className="featured-text">
                              Xerotag{" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 500 150"
                                preserveAspectRatio="none"
                              >
                                <path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path>
                              </svg>
                            </span>{" "}
                            for Mobile App Development Company?
                          </h2>
                        </div>
                      </div>
                      <div className="info-items-list">
                        <div className="row ">
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">01.</span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Expert Team
                                </h5>
                                <p className="info-text">
                                Our developers, designers and project managers bring years of experience and exceptional skills in mobile app development, ensuring superior results.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">02.</span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Custom Solutions
                                </h5>
                                <p className="info-text">
                                We tailor each app to your specific business needs, building a unique, feature-rich application that matches your goals and brand identity.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">03.</span>
                              <div className="info-content">
                                <h5 className="info-title">
                                End-to-End Services
                                </h5>
                                <p className="info-text">
                                From initial consultations to post-launch support, we offer comprehensive mobile app development services, guiding you through every step of the process.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">04.</span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Cutting-Edge Technology
                                </h5>
                                <p className="info-text">
                                We use the latest tools and technologies such as Swift, Kotlin, Flutter, and React Native to build high-performance applications for iOS and Android.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">05.</span>
                              <div className="info-content">
                                <h5 className="info-title">User-Centric Design</h5>
                                <p className="info-text">
                                Our focus on user experience ensures that your app is not only functional but also fun and easy to use, increasing customer satisfaction and retention.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">06.</span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Proven Track Record
                                </h5>
                                <p className="info-text">
                                With a portfolio of successful brands across a variety of industries, Xerotag has a history of delivering high-ranking and productivity-enhancing apps.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">06.</span>
                              <div className="info-content">
                                <h5 className="info-title">
                                Ongoing Support
                                </h5>
                                <p className="info-text">
                                We provide ongoing support and maintenance for your application after launch, to ensure it stays up-to-date, secure and in line with evolving technological developments.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cta-area ">
                        <div
                          className=" btn-solid "
                          onClick={() => scrollToSection("contact-us")}
                        >
                          get in touch
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <ContactUsPg />
      <Footer />
  </div>
  )
}

export default AppDevService
