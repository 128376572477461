import Body from "./components/Body";
import 'bootstrap/dist/css/bootstrap.min.css';           /* Bootstrap */
import "bootstrap-icons/font/bootstrap-icons.css";        /* Bootstrap Icon */
import "swiper/swiper-bundle.css";
import './assets/css/main-LTR.css';
import './assets/css/jquery.fancybox.min.css';
import './assets/css/animate.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import WebdevService from "./components/WebdevService";
import DigitalMarketingService from "./components/DigitalMarketingService";
import AppDevService from "./components/AppDevService";
import SEOService from "./components/SEOService";
import NotFound from "./components/ErrorBoundary";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Body />,
  },
  {
    path: "/web-development",
    element: <WebdevService />,
  },
  {
    path: "/digital-marketing",
    element: <DigitalMarketingService />,
  },
  {
    path: "/app-development",
    element: <AppDevService />,
  },
  {
    path: "/seo",
    element: <SEOService />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

function App() {
  return (
      <div>
        <RouterProvider router={router} />
      </div>
  );
}

export default App;
