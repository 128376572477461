import {
  faCube,
  faGear,
  faInfinity,
  faListUl,
  faLocationDot,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import Particles from "react-particles-js";
import VanillaTilt from "vanilla-tilt";
import { customParticlesOptions } from "../main";
import ContactUsPg from "./sections/ContactUsPg";
import Footer from "./Footer";
import Header from "./Header ";

const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
  }
};

const WebdevService = () => {
  // const [showBackground, setShowBackground] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tiltRefs = useRef([]);
  useLayoutEffect(() => {
    tiltRefs.current.forEach((ref) => {
      if (ref) {
        VanillaTilt.init(ref, {
          max: 10, // Maximum tilt rotation (degrees)
          speed: 400, // Speed of the tilt effect
          scale: 1, // Scale factor on tilt
          glare: false, // Enable glare effect
          "max-glare": 0.5, // Maximum glare opacity
        });
      }
    });
  }, [tiltRefs]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        // setShowBackground(true);
      } else {
        // setShowBackground(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {/* --------------------------Header---------------------------------- */}
  <Header/>
      {/* ----------------------particles----------------------- */}
      <div>
        <section
          className="page-hero services service-content  d-flex align-items-center reveal-start"
          id="page-hero"
        >
          <div className="particles-js dots" id="particles-js">
            <Particles
              params={{
                ...customParticlesOptions,
              }}
            />
          </div>
          {/* -------------------------home pg */}
          <div className="container">
            <div className="row align-items-center justify-content-center text-center ">
              <div className="col-12 col-lg-9">
                <div className="hero-text-area   ">
                  <div className="content-area ">
                    <h1
                      className="title    wow fadeInUp"
                      data-wow-delay=".4s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.4s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <span className="hollow-text ">Web Development </span>{" "}
                      <span className="featured-text">
                        {" "}
                        Company
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 500 150"
                          preserveAspectRatio="none"
                        >
                          <path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path>
                        </svg>
                      </span>{" "}
                    </h1>
                  </div>
                  <div className="col-11 ">
                    <p className="hero-subtitle text-center ">
                      We are a leading web development company that specializes
                      in affordable custom websites. Our experienced team uses
                      cutting-edge technology to create responsive,
                      user-friendly websites that enhance your online presence
                      and grow your business.
                    </p>
                  </div>
                </div>
                <div
                  className=" btn-solid text-center "
                  onClick={() => scrollToSection("contact-us")}
                >
                  get in touch
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* -------------------------(start) Out web dev services------------------------ */}
        <div>
          <section
            className="services services-boxed mega-section"
            id="services"
          >
            <div className="container">
              <div className="sec-heading  ">
                <div className="content-area">
                  <div>
                    <div className="content-area">
                      <h2
                        className="title    wow fadeInUp"
                        data-wow-delay=".4s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.4s",
                          animationName: "fadeInUp",
                        }}
                      >
                        <span className="hollow-text">Our Website </span>{" "}
                        <span className="featured-text">
                          {" "}
                          Development
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 500 150"
                            preserveAspectRatio="none"
                          >
                            <path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path>
                          </svg>
                        </span>{" "}
                        Services
                      </h2>
                    </div>
                    <p
                      className="subtitle wow fadeInUp"
                      data-wow-delay=".6s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.6s",
                        animationName: "fadeInUp",
                      }}
                    >
                      Web development is a blend of technology and creativity.
                      At Xerotag, we craft websites
                      <br />
                      that combine innovative design with strategic
                      functionality, ensuring an engaging <br />
                      experience that captivates and retains your audience.{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="row gx-4 gy-4 services-row ">
                <div className="col-12 col-md-6  col-lg-6 mx-auto ">
                  <div
                    className="box service-box  wow fadeInUp "
                    data-wow-offset="0"
                    data-wow-delay=".2s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.2s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <span className="service-num hollow-text"></span>
                    <div className="service-content">
                      <h3 className="service-title">
                        Web Development Consulting{" "}
                      </h3>
                      <p className="service-text">
                        Seeking expert advice for your web strategy? Xerotag
                        offers comprehensive consulting services, including
                        system architecture design, performance optimization,
                        and more. We use Agile and DevOps best practices to
                        ensure a streamlined and efficient web development
                        process, helping your business achieve its online goals
                        effectively and efficiently.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6  col-lg-6 mx-auto  ">
                  <div
                    className="box service-box  wow fadeInUp reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".3s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.3s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon"></div>
                    <span className="service-num hollow-text"></span>
                    <div className="service-content">
                      <h3 className="service-title">Web UI/UX Design </h3>
                      <p className="service-text">
                        We prioritize your website's user experience. Our
                        talented UI/UX designers utilize tools like Sketch and
                        Adobe XD to create prototypes, ensuring your web
                        solutions are both highly functional and visually
                        engaging, delivering a seamless and appealing user
                        experience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6  col-lg-6 mx-auto  ">
                  <div
                    className="box service-box  wow fadeInUp reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".4s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.4s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon"></div>
                    <span className="service-num hollow-text"></span>
                    <div className="service-content">
                      <h3 className="service-title">Website Development </h3>
                      <p className="service-text">
                        Ready to enhance your digital presence? Xerotag's web
                        development team specializes in creating custom,
                        responsive, and SEO-optimized websites. We use the
                        latest technologies, including HTML5, CSS3, and
                        JavaScript frameworks, to build scalable websites that
                        maximize user engagement and drive business growth.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6  col-lg-6 mx-auto  ">
                  <div
                    className="box service-box  wow fadeInUp reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".5s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.5s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon"></div>
                    <span className="service-num hollow-text">5 </span>
                    <div className="service-content">
                      <h3 className="service-title">Web Portal Development</h3>
                      <p className="service-text">
                        Looking for a robust, interactive web portal? Xerotag
                        specializes in developing customized web portals that
                        integrate advanced functionalities. We use technologies
                        like Angular and ReactJS for front-end development,
                        combined with Node.js or Python for backend systems,
                        ensuring seamless data handling and efficient user
                        management.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6  col-lg-6 mx-auto  ">
                  <div
                    className="box service-box  wow fadeInUp  reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".6s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.6s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon"></div>
                    <span className="service-num hollow-text">6</span>
                    <div className="service-content">
                      <h3 className="service-title"> Web App Development</h3>
                      <p className="service-text">
                        We specialize in designing and developing
                        high-performance web application platforms, including
                        PWAs and SPAs, to deliver fast, responsive, and engaging
                        user experiences. Whether you need an e-commerce
                        platform, business management tool, or customer portal,
                        our expert team has the skills and knowledge to bring
                        your vision to life effectively.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6  col-lg-6 mx-auto  ">
                  <div
                    className="box service-box  wow fadeInUp  reveal-start"
                    data-wow-offset="0"
                    data-wow-delay=".6s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.6s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="service-icon"></div>
                    <span className="service-num hollow-text">6</span>
                    <div className="service-content">
                      <h3 className="service-title">Website Migration</h3>
                      <p className="service-text ">
                        Xerotag offers comprehensive website migration services,
                        including data transfer, platform upgrades, and cloud
                        integration using AWS and Azure. We ensure a seamless,
                        secure transition to modern web infrastructures with
                        minimal downtime, enhancing performance and scalability
                        for your online presence.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* -----------------------------Features of webdev we have----------------------------------- */}
        <div>
          <section className="about mega-section">
            <div className="container">
              <div className="content-block  ">
                <div className="row">
                  <div
                    className="col-12 col-lg-12 d-flex align-items-center order-1 order-lg-0 about-col pad-end  wow fadeInUp"
                    data-wow-delay="0.6s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.6s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="text-area ">
                      <div className="sec-heading  light-title ">
                        <div className="content-area">
                          <h2
                            className="title    wow fadeInUp"
                            data-wow-delay=".4s"
                            style={{
                              visibility: "visible",
                              animationDelay: "0.4s",
                              animationName: "fadeInUp",
                            }}
                          >
                            <span className="hollow-text">Feature </span> of{" "}
                            <span className="featured-text">
                              {" "}
                              Web Development
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 500 150"
                                preserveAspectRatio="none"
                              >
                                <path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path>
                              </svg>
                            </span>{" "}
                            We Have
                          </h2>
                        </div>
                      </div>

                      <div className="info-items-list ">
                        <div className="row ">
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faGear} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">
                                  Web Compatibility{" "}
                                </h5>
                                <p className="info-text Webservice">
                                  At Xerotag, our web development ensures full
                                  compatibility across all web browsers,
                                  devices, and platforms. We create responsive
                                  websites, web applications, and software that
                                  are user-friendly and enhance your search
                                  engine rankings.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faSpinner} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">
                                  Fast Loading Speed{" "}
                                </h5>
                                <p className="info-text">
                                  We design websites and applications optimized
                                  for fast loading times, keeping users engaged.
                                  Our clean, bug-free code ensures quick
                                  performance across all browsers, improving
                                  user experience and reducing bounce rates.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faLocationDot} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">Easy Navigation</h5>
                                <p className="info-text">
                                  With nearly 94% of users valuing easy
                                  navigation, we focus on building websites with
                                  intuitive, user-friendly navigation to enhance
                                  overall usability and user satisfaction.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faListUl} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">Usable Forms </h5>
                                <p className="info-text">
                                  Forms are crucial for user interaction and
                                  lead generation. We implement simple,
                                  easy-to-fill forms that help gather valuable
                                  information, making it easier to connect with
                                  potential clients and grow your customer base.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faCube} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">Blog Integration</h5>
                                <p className="info-text">
                                  We integrate blogs into your website to inform
                                  customers about your products and services
                                  while boosting search engine optimization
                                  efforts, helping to increase traffic and
                                  online visibility.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-9 col-xl-4">
                            <div className="info-item">
                              <span className="info-icon">
                                <FontAwesomeIcon icon={faInfinity} />
                              </span>
                              <div className="info-content">
                                <h5 className="info-title">
                                  Product and Content Visibility
                                </h5>
                                <p className="info-text">
                                  Our web developers create sites that highlight
                                  product and content visibility, boosting sales
                                  and revenue. Enhanced visibility helps improve
                                  conversion rates and generate more business
                                  opportunities.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* -------------------------(start) WEbDev technologies we are expert in -------------------------------- */}
        <div>
          <section>
            <div className="container">
              <div
                className="col-12 col-lg-10 d-flex align-items-center order-1 order-lg-0 about-col pad-end  wow fadeInUp"
                data-wow-delay="0.6s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.6s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="sec-heading  light-title ">
                  <div className="content-area">
                    <h2
                      className="title    wow fadeInUp"
                      data-wow-delay=".4s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.4s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <span className="hollow-text">Web Development </span>{" "}
                      <span className="featured-text">
                        {" "}
                        Technologies
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 500 150"
                          preserveAspectRatio="none"
                        >
                          <path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path>
                        </svg>
                      </span>{" "}
                      We Are Experts In
                    </h2>
                  </div>
                </div>
              </div>

              <div>
                <div
                  className="our-technologies big-section wow fadeInUp"
                  data-wow-delay="0.2s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.2s",
                    animationName: "fadeInUp",
                  }}
                >
                  <div className="tech-logos d-flex align-items-center justify-content-around flex-wrap">
                    <div className="tech-logos ">
                      <div
                        className="technology-logo  "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/php.png"}
                          alt="saffronIvy "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/python.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/java.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/nodejs(1).png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{
                          width: "160px",
                          marginRight: "30px",
                          alignContent: "center",
                        }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/pngwing.com(3).png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/shopify(1).png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{
                          width: "160px",
                          marginRight: "30px",
                          alignContent: "center",
                        }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/pngwing.com.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{
                          width: "160px",
                          marginRight: "30px",
                          alignContent: "center",
                        }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/pngwing.com(1).png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo  "
                          loading="lazy"
                          src={"/image/drupal.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/react.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/pngwing.com(4).png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/magento.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/flutter.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/swift.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/angular.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/vuejs.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/pngwing.com(5).png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/mysql.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/firebase.png"}
                          alt=" "
                        />
                      </div>

                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/typescript.png"}
                          alt=" "
                        />
                      </div>
                      <div
                        className="technology-logo "
                        style={{ width: "160px", marginRight: "30px" }}
                      >
                        <img
                          className="img-fluid logo "
                          loading="lazy"
                          src={"/image/html.png"}
                          alt=" "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div>
          {/* -------------------------------------(start) Why choose us page--------------------------- */}
          <section className="about mega-section" id="about">
            <div className="container">
              <div className="content-block  ">
                <div className="row">
                  <div
                    className="col-12 col-lg-6 d-flex align-items-center about-col  wow fadeInUp"
                    data-wow-delay="0.2s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.2s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div
                      ref={(ref) => (tiltRefs.current[2] = ref)}
                      className="img-area  "
                      style={{
                        willChange: "transform",
                        transform:
                          "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
                      }}
                    >
                      <div className="image  ">
                        <img
                          className="about-img img-fluid "
                          loading="lazy"
                          src={"/image/1.png"}
                          alt="about"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-12 col-lg-6 d-flex align-items-center about-col pad-start  wow fadeInUp"
                    data-wow-delay="0.6s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.6s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="text-area ">
                      <div className="sec-heading  light-title ">
                        <div className="content-area">
                          <h2
                            className="title    wow fadeInUp"
                            data-wow-delay=".4s"
                            style={{
                              visibility: "visible",
                              animationDelay: "0.4s",
                              animationName: "fadeInUp",
                            }}
                          >
                            Why <span className="hollow-text">Choose </span>{" "}
                            <span className="featured-text">
                              Xerotag{" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 500 150"
                                preserveAspectRatio="none"
                              >
                                <path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path>
                              </svg>
                            </span>{" "}
                            for Web Development Company?
                          </h2>
                        </div>
                      </div>
                      <div className="info-items-list">
                        <div className="row ">
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">01.</span>
                              <div className="info-content">
                                <h5 className="info-title">
                                  Custom Web Solutions
                                </h5>
                                <p className="info-text">
                                  At Xerotag, we take a personalized approach by
                                  working closely with our clients to understand
                                  their unique business goals, target audience,
                                  and changing market trends, ensuring tailored
                                  web solutions that meet their needs.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">02.</span>
                              <div className="info-content">
                                <h5 className="info-title">
                                  User-Centric Design
                                </h5>
                                <p className="info-text">
                                  Our talented design team expertly combines
                                  aesthetics with usability, creating visually
                                  stunning and intuitive interfaces that
                                  captivate users from their very first
                                  interaction.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">03.</span>
                              <div className="info-content">
                                <h5 className="info-title">
                                  Robust Development Process
                                </h5>
                                <p className="info-text">
                                  We follow a structured development process,
                                  from initial concept to final deployment,
                                  using industry best practices and agile
                                  methodologies to promote iterative development
                                  and effective collaboration.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">04.</span>
                              <div className="info-content">
                                <h5 className="info-title">
                                  Post-Launch Support
                                </h5>
                                <p className="info-text">
                                  Xerotag is committed to your long-term
                                  success. We offer comprehensive post-launch
                                  support and maintenance to keep your web
                                  application running smoothly and efficiently.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">05.</span>
                              <div className="info-content">
                                <h5 className="info-title">Flexibility</h5>
                                <p className="info-text">
                                  Our team’s flexibility is unmatched. With over
                                  a decade of experience, our seasoned experts
                                  are proficient in a wide range of technology
                                  stacks, delivering results tailored to your
                                  specific requirements.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="info-item">
                              <span className="info-number ">06.</span>
                              <div className="info-content">
                                <h5 className="info-title">
                                  Proven Track Record
                                </h5>
                                <p className="info-text">
                                  Our extensive portfolio showcases numerous
                                  successful web projects that have earned
                                  recognition and driven growth for businesses
                                  across various industries. With Xerotag,
                                  you're partnering with a trusted and
                                  experienced team.practices and agile
                                  methodologies to promote iterative development
                                  and effective collaboration.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cta-area ">
                        <div
                          className=" btn-solid "
                          onClick={() => scrollToSection("contact-us")}
                        >
                          get in touch
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <ContactUsPg />
      <Footer />
    </div>
  );
};

export default WebdevService;
