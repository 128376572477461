import React, { useEffect, useRef } from 'react';
import "../../assets/css/swiper-bundle.min.css";
import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { useWowAnimation } from '../../hooks/useWowAnimation';

/* =========================    our-clients Section    =======================*/

const ClientPg = () => {
    useWowAnimation();
    const swiperRef = useRef(null);

    useEffect(() => {
        const swiper = new Swiper(swiperRef.current, {
            // Swiper options
            speed: 600,
            slidesPerView: 3,
            spaceBetween: 20,
            delay: 6000,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            breakpoints: {
                991: {
                    slidesPerView: 6,
                    spaceBetween: 30,
                },
            },
            pagination: false,
            loop: true,
            navigation: false,
            modules: [Autoplay, Pagination, Navigation],
        });
        swiper.init();
    }, []);

    return (
        // <!-- Start  our-clients Section-->
        <div>
            <section className="our-clients mega-section wow fadeInUp" id="our-clients" data-wow-delay="0.2s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp' }}>
                <div className="container" >
                    <div className="sec-heading centered ">
                        <div className="content-area">
                            <h2 className="title wow fadeInUp" data-wow-delay=".4s" style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp' }}>trusted by over 200 clients around the world</h2>
                        </div>
                    </div>
                    <div className=" clients-logos d-flex align-items-center justify-content-around flex-wrap">
                        {/* <!--Swiper--> */}
                        <div ref={swiperRef} className="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events" style={{ cursor: 'grab' }}>
                            <div className="swiper-wrapper clients-logo-wrapper wow fadeIn" data-wow-delay=".02s" id="swiper-wrapper-0510be116801144b0" aria-live="off" style={{ transitionDuration: '0ms', visibility: 'visible', animationDelay: '0.02s', animationName: 'fadeIn', transform: 'translate3d(-2652px, 0px, 0px)' }}>
                                <div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="1" style={{ width: '191px', marginRight: '30px' }}>
                                    <div className="client-logo ">
                                        <img className="img-fluid logo " loading="lazy" src={"/image/2-white.png"} alt="saffronIvy " />
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="2" style={{ width: '191px', marginRight: '30px' }}>
                                    <div className="client-logo ">
                                        <img className="img-fluid logo " loading="lazy" src={"/image/3-white.png"} alt=" " />
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="3" style={{ width: '191px', marginRight: '30px' }}>
                                    <div className="client-logo ">
                                        <img className="img-fluid logo " loading="lazy" src={"/image/4-white.png"} alt=" " />
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="4" style={{ width: '191px', marginRight: '30px' }}>
                                    <div className="client-logo ">
                                        <img className="img-fluid logo " loading="lazy" src={"/image/5-white.png"} alt=" " />
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="5" style={{ width: '191px', marginRight: '30px' }}>
                                    <div className="client-logo ">
                                        <img className="img-fluid logo " loading="lazy" src={"/image/6-white.png"} alt=" " />
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index="6" style={{ width: '191px', marginRight: '30px' }}>
                                    <div className="client-logo ">
                                        <img className="img-fluid logo " loading="lazy" src={"/image/7-white.png"} alt=" " />
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate-next" data-swiper-slide-index="0" style={{ width: '191px', marginRight: '30px' }}>
                                    <div className="client-logo  ">
                                        <img className="img-fluid logo " loading="lazy" src={"/image/1-white.png"} alt=" " />
                                    </div>
                                </div>
                                <div className="swiper-slide" data-swiper-slide-index="1" style={{ width: '191px', marginRight: '30px' }}>
                                    <div className="client-logo ">
                                        <img className="img-fluid logo " loading="lazy" src={"/image/2-white.png"} alt=" " />
                                    </div>
                                </div>
                                <div className="swiper-slide" data-swiper-slide-index="2" style={{ width: '191px', marginRight: '30px' }}>
                                    <div className="client-logo ">
                                        <img className="img-fluid logo " loading="lazy" src={"/image/3-white.png"} alt=" " />
                                    </div>
                                </div>
                                <div className="swiper-slide" data-swiper-slide-index="3" style={{ width: '191px', marginRight: '30px' }}>
                                    <div className="client-logo ">
                                        <img className="img-fluid logo " loading="lazy" src={"/image/4-white.png"} alt=" " />
                                    </div>
                                </div>
                                <div className="swiper-slide" data-swiper-slide-index="4" style={{ width: '191px', marginRight: '30px' }}>
                                    <div className="client-logo ">
                                        <img className="img-fluid logo " loading="lazy" src={"/image/5-white.png"} alt=" " />
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-prev" data-swiper-slide-index="5" style={{ width: '191px', marginRight: '30px' }}>
                                    <div className="client-logo ">
                                        <img className="img-fluid logo " loading="lazy" src={"/image/6-white.png"} alt=" " />
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-active" data-swiper-slide-index="6" style={{ width: '191px', marginRight: '30px' }}>
                                    <div className="client-logo ">
                                        <img className="img-fluid logo " loading="lazy" src={"/image/7-white.png"} alt=" " />
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-next" data-swiper-slide-index="0" style={{ width: '191px', marginRight: '30px' }}>
                                    <div className="client-logo  ">
                                        <img className="img-fluid logo " loading="lazy" src={"/image/1-white.png"} alt=" " />
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="1" style={{ width: '191px', marginRight: '30px' }}>
                                    <div className="client-logo ">
                                        <img className="img-fluid logo " loading="lazy" src={"/image/2-white.png"} alt=" " />
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="2" style={{ width: '191px', marginRight: '30px' }}>
                                    <div className="client-logo ">
                                        <img className="img-fluid logo " loading="lazy" src={"/image/3-white.png"} alt=" " />
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="3" style={{ width: '191px', marginRight: '30px' }}>
                                    <div className="client-logo ">
                                        <img className="img-fluid logo " loading="lazy" src={"/image/4-white.png"} alt=" " />
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="4" style={{ width: '191px', marginRight: '30px' }}>
                                    <div className="client-logo ">
                                        <img className="img-fluid logo " loading="lazy" src={"/image/5-white.png"} alt=" " />
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="5" style={{ width: '191px', marginRight: '30px' }}>
                                    <div className="client-logo ">
                                        <img className="img-fluid logo " loading="lazy" src={"/image/6-white.png"} alt=" " />
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev" data-swiper-slide-index="6" style={{ width: '191px', marginRight: '30px' }}>
                                    <div className="client-logo ">
                                        <img className="img-fluid logo " loading="lazy" src={"/image/7-white.png"} alt=" " />
                                    </div>
                                </div>
                            </div>
                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default ClientPg;


